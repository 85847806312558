<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form" ref="patientForm" @submit.prevent="savePatients">
                    <v-card :elevation="1" color="grey lighten-4">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar color="grey lighten-4" :elevation="1" height="30px">
                                    <v-toolbar-title class="subtitle-2">Patients Entry</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="py-0 mt-1">
                                    <v-row dense>
                                        <v-col cols="2"></v-col>
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1"> 
                                                <v-col cols="3">Pnt. ID</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :value="$store.getters['patient/newCode']"
                                                        readonly
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Pnt. Name</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="patient.name"
                                                        :rules="[() => !!this.patient.name || '']"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Mobile</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="patient.mobile"
                                                        :rules="[() => !!this.patient.mobile || '']"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters> 
                                                <v-col cols="3" class="mt-1">Gender</v-col>
                                                <v-col cols="9">
                                                    <v-radio-group row v-model="patient.gender" no-gutters style="margin:0px;padding:0px">
                                                        <v-radio
                                                            label="Male"
                                                            value="Male"
                                                        ></v-radio>
                                                        <v-radio
                                                            label="Female"
                                                            value="Female"
                                                        ></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Age</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="patient.age"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Address</v-col>
                                                <v-col cols="9">
                                                    <v-textarea
                                                        dense
                                                        outlined
                                                        hide-details
                                                        height="8vh"
                                                        v-model="patient.address"
                                                    ></v-textarea>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3"></v-col>
                                                <v-col cols="9">
                                                    <v-row dense>
                                                         <v-col cols="6">
                                                            <v-btn height="30px" type="reset" dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-btn height="30px" type="submit" dark block color="light-blue darken-2" :loading="isloading" >Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="mt-1">
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="patientHeaders"
                    :loading="$store.getters['patient/loading']"
                    :items="$store.getters['patient/patients']"
                    :search="searchPatient"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Patient List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    v-model="searchPatient"
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Patient"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-if="userType != 'user'" v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="editPatient(item)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="patientId=item.id;$refs.confirmDialog.dialog = true" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
                </v-data-table>
                <confirm-dialog ref="confirmDialog" @confirm="deletePatient"></confirm-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import confirmDialog from "./../../components/Confirm";
export default {
    name: 'PatientEntry',

    components: {
		"confirm-dialog": confirmDialog,
    },

    data: ()=> ({
        patients: [],
        patient: {
            id: null,
            name: '',
            mobile: '',
            gender: '',
            age: null,
            address: ''
        },

        patientHeaders: [
            { text: 'Id', value: 'code' },
            { text: 'Name', value: 'name' },
            { text: 'Mobile', value: 'mobile' },
            { text: 'Gender', value: 'gender' },
            { text: 'Age', value: 'age' },
            { text: 'Address', value: 'address' },
            { text: 'Action', value: 'action' },
        ],
        searchPatient: '',
        isloading: false,
        patientId: null,
        userType: ''
    }),

    created() {
        this.getPatients();
        this.getCode();
        let userData = JSON.parse(localStorage.getItem('_userData'))
        this.userType = userData.user_type
    },

    methods: {
        getPatients() {
            this.$store.dispatch('patient/getPatients');
        },

        getCode() {
            this.$store.dispatch('patient/getPatientCode');
        },

        async savePatients() {
            if(! this.validationForm()) {
                return;
            }
        
            this.isloading = true;
            let isSuccess = await this.$store.dispatch('patient/savePatients', this.patient);

            if(isSuccess) {
                this.resetForm();
            }
            this.isloading = false;
        },

        editPatient(patient) {
            Object.keys(this.patient).forEach(key => this.patient[key] = patient[key]);
        },

        deletePatient() {
            this.$store.dispatch('patient/deletePatient', { id: this.patientId });
            this.$refs.confirmDialog.dialog = false;
        },

        validationForm() {
            let isValid = true;
            this.$refs.patientForm.validate();
            this.$refs.patientForm.inputs.forEach(input => {
                if(input.hasError) isValid = false;
            })

            return isValid;
        },

        resetForm() {
            this.$refs.patientForm.reset();
            this.$refs.patientForm.resetValidation();
            this.getCode();
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
    .v-messages {
        flex: 1 1 auto;
        font-size: 12px;
        min-height: 0px !important;
        max-height: 0px!important;
        min-width: 1px;
        position: relative;
    }
</style>